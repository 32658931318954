<template> <!-- Los campos de la búsqueda -->
  <v-container grid-list-xl fluid class="mt-8">
    <v-row cols="12" class="pl-4 pt-4 pr-4 pb-2">
      <v-col xl="10" lg="10" md="8" class="pt-0 pb-0">
        <span class="myText secondary--text">Búsqueda avanzada</span> <!--TODO coger el valor del filtrado en vez corta cespeds -->
      </v-col>
      <v-col xl="2" lg="2" md="4" class="pt-0 pb-0">
        <v-btn text color="primary" class="pr-1" style="float: right;" @click="cleanFilters">
          <span class="secondary--text">Borrar filtros</span>
          <v-icon right color="red">mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-layout wrap class="pl-4 pr-4 pb-1">
      <v-flex xs6 md4 sm4>
        <v-select
          :items="brandslist"
          label="Seleccionar Marca"
          item-text="machinebrandname"
          item-value="machinebrandid"
          v-model="selectedbrand"
          outlined
          color="secondary"
          background-color="primary"
        ></v-select>
      </v-flex>
       <v-flex xs6 md4 sm4>
        <v-select
          :items="machinetypes"
          label="Seleccionar Tipo"
          item-text="typename"
          item-value="typeid"
          v-model="selectedtype"
          outlined
          color="secondary"
          background-color="primary"
         ></v-select>
        </v-flex>
        <v-flex xs12 md4 sm4>
          <v-text-field
            v-model="keywords"
            label="Palabras clave"
            outlined
            color="secondary"
            background-color="primary"
          >
          </v-text-field>
        </v-flex>
        <v-flex xs6 md4 sm4>
          <v-menu
            v-model="menu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="datefrom"
                label="Fecha de inicio"
                append-icon="mdi-calendar"
                readonly
                v-on="on"
                outlined
                color="secondary"
                background-color="primary"
              ></v-text-field>
            </template>
            <v-date-picker v-model="datefrom" :min="today" no-title locale="es-es" @input="menu = false"></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs6 md4 sm4>
          <v-menu
            v-model="menu2"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateto"
                label="Fecha de fin"
                append-icon="mdi-calendar"
                readonly
                v-on="on"
                outlined
                color="secondary"
                background-color="primary"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dateto" no-title locale="es-es"  @input="menu2 = false"></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs12 md4 sm4 style="text-align: center;">
          <!-- <v-checkbox
            v-model="allowSpaces"
            label="Sólo equipos Premium"
            color="secondary"
            :ripple="false"
          ></v-checkbox> -->

          <v-spacer></v-spacer>
          <v-switch
            v-model="allowSpaces"
            :label="'Sólo equipos Premium'"
            color="secondary"
          ></v-switch>
        </v-flex>
        <v-flex xs12 md4 sm4 class="pt-0">
          <v-card flat color="transparent" >
            <!-- <v-slider
              v-model="rangePrice"
              :label="'Precio por día ( € )'"
              :min="minPrice"
              :max="maxPrice"
              thumb-label="always"
              color="secondary"
            ></v-slider> -->
            <v-subheader class="pl-0">Precio por día ( € )</v-subheader>
            <v-range-slider
              v-model="rangePrice"
              :max="maxPrice"
              :min="minPrice"
              hide-details
              class="align-center"
              color="secondary"
            >
              <template v-slot:prepend>
                <v-text-field
                  v-model="rangePrice[0]"
                  class="mt-0 pt-0 myInput"
                  hide-details
                  single-line
                  :min="minPrice"
                  :max="maxPrice"
                  type="number"
                  style="width: 40px"
                  color="secondary"
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  v-model="rangePrice[1]"
                  class="mt-0 pt-0 myInput"
                  :min="minPrice"
                  :max="maxPrice"
                  hide-details
                  single-lined
                  type="number"
                  style="width: 40px"
                  color="secondary"
                ></v-text-field>
              </template>
            </v-range-slider>
          </v-card>
        </v-flex>
       <v-flex xs12 md4 sm4 class="pt-0">
        <v-card flat color="transparent">
          <!-- <v-slider
            v-model="rangePot"
            :label="'Potencia ( CV )'"
            :max="maxPot"
            :min="minPot"
            color="secondary"
            thumb-label="always"
          ></v-slider> -->
          <v-subheader class="pl-0">Potencia ( CV )</v-subheader>
          <v-range-slider
              v-model="rangePot"
              :max="maxPot"
              :min="minPot"
              hide-details
              class="align-center"
              color="secondary"
            >
              <template v-slot:prepend>
                <v-text-field
                  v-model="rangePot[0]"
                  class="mt-0 pt-0 myInput"
                  hide-details
                  single-line
                  :min="minPot"
                  :max="maxPot"
                  type="number"
                  style="width: 40px"
                  color="secondary"
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  v-model="rangePot[1]"
                  class="mt-0 pt-0 myInput"
                  :min="minPot"
                  :max="maxPot"
                  hide-details
                  single-lined
                  type="number"
                  style="width: 40px"
                  color="secondary"
                ></v-text-field>
              </template>
            </v-range-slider>
        </v-card>          
      </v-flex>
      <v-flex xs12 md4 sm4 align-center>
        <v-btn class="secondary" @click="searchMachines();" style="float: right; margin-top: 45px;">Buscar</v-btn>
      </v-flex>
      <!-- <v-dialog v-model="isWaiting" max-width="500px" persistent fullscreen>
        <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
          <v-layout justify-center align-center> -->
          <v-overlay :absolute="true" :value="isWaiting">
            <v-progress-circular
              indeterminate
              color="dcdcdc"
              :size="100"
              :width="5"
            ></v-progress-circular>
          </v-overlay>
          <!-- </v-layout>
        </v-container>
      </v-dialog> -->
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      allowSpaces: false,
      brandslist: undefined,
      machinetypes: undefined,
      match: '',
      max: 0,
      model: '',
      selectedtype: 0,
      selectedbrand: 0,
      dateto: null,
      keywords: null,
      datefrom: null,
      menu: false,
      modal: false,
      menu2: false,
      minPrice: 0,
      maxPrice: 1000,
      minPot: 0,
      maxPot: 1000,
      slider: 40,
      rangePrice: [0, 1000],
      rangePot: [0, 1000],
      today: undefined
    }),
    computed: {
      isWaiting(){
        return this.$store.state.isLoading
      },
      dateFormattedFrom () {
        return this.formatDate(this.datefrom)
      },
      rules () {
        const rules = []
        if (this.max) {
          const rule = v => (v || '').length <= this.max || `A maximum of ${this.max} characters is allowed`
          rules.push(rule)
        }
        if (!this.allowSpaces) {
          const rule = v => (v || '').indexOf(' ') < 0 || 'No spaces are allowed'
          rules.push(rule)
        }
        if (this.match) {
          const rule = v => (!!v && v) === this.match || 'Values do not match'
          rules.push(rule)
        }
        return rules
      },
    },
    watch: {
      match: 'validateField',
      max: 'validateField',
      model: 'validateField',
    },
    mounted: function(){
      this.$store.commit('resetStore')
      this.today = this.getToday()
      this.getBrandsList();
      this.getMachineTypes()
    },
    methods: {
      validateField () {
        this.$refs.form.validate()
      },
        getBrandsList() {
          const self = this
          self.isLoading = true
          self.axios.get(`${process.env.VUE_APP_BASE_URI}${'/getmachinebrandslist'}`).then(
            function(success) {
              self.brandslist = success.data.machinebrands
            },
            function() {
              self.isLoading = false
              self.$toast.open({
                duration: 5000,
                message: self.$t('myMachinesErrorGetMachines'),
                type: 'is-danger'
              })
            }
          )
        },
        getMachineTypes() {
            const self = this
            self.isLoading = true
            self.axios.get(`${process.env.VUE_APP_BASE_URI}${'/getmachinetypes'}`).then(
                function(success) {
                    self.machinetypes = success.data.types
                },
                function() {
                    self.isLoading = false
                    self.$toast.open({
                        duration: 5000,
                        message: self.$t('myMachinesErrorGetMachines'),
                        type: 'is-danger'
                    })
                }
            )
    },
    searchMachines() {
        // var plan = 1
        this.waiting=true
       // alert(this.datefrom)
        var filter={}
        if( this.selectedtype != 0)
          filter.machinetype = this.selectedtype
        if( this.selectedbrand != 0)
          filter.machinebrand = this.selectedbrand
        if( this.keywords != null)
          filter.texttosearch = this.keywords
        if( this.datefrom != null)
          filter.from = this.datefrom
        if( this.dateto != null)
          filter.to = this.dateto
        if( this.rangePrice[0] > 0)
          filter.pricefrom = this.rangePrice[0]
        if( this.rangePrice[1] < 1000)
          filter.priceto = this.rangePrice[1]
        if( this.rangePot[0] > 0)
          filter.powerfrom = this.rangePot[0]
        if( this.rangePot[1] < 1000)
          filter.powerfrom = this.rangePot[1]
        this.$store.dispatch('getFilteredMachines', filter);

    },
    cleanFilters(){
      this.selectedtype = 0;
      this.selectedbrand = 0
      this.dateto = null
      this.datefrom = null
      this.keywords = null
      this.rangePrice=[0,1000]
      this.rangePot=[0,1000]
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    getToday() {
      return new Date().toISOString().substr(0, 10)
    }
  }
}
</script>

<style lang="css">
.myText {
  font-size: 1.2rem;
}

.myInput input[type='number'] {
  -moz-appearance:textfield;
}
.myInput input::-webkit-outer-spin-button,
.myInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* ::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

.v-list .v-list-item--active {
  /* color: inherit; */
  color: #14131c !important;
  background-color: rgba(0, 0, 0, 0.3);
  caret-color: #14131c !important;
  
}
.v-list-item__title {
  color: #14131c!important;
}
</style>