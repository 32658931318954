<template>
  <v-container grid-list-lg>
    <v-layout row wrap>
      <v-flex xs12 sm12 md12 lg12 xl12>
        <v-card color="accent">
          <search-form></search-form>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- <v-row>
      <v-col style="text-align: center;">
        <v-btn text color="primary" class="pl-1" @click="goToCategoryFilter">
          <v-icon left color="secondary">mdi-rhombus-split</v-icon>
          <span class="secondary--text">Búsqueda por categoría</span>
        </v-btn>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import SearchForm from '@/components/SearchForm'
export default {
  name: 'Menu',
  components: {
    SearchForm
  },
  created() {
    this.$store.commit('isResultsVisible', false)
  },
  methods: {
    goToCategoryFilter() {
      this.$router.push({ path: '/' })
    }
  }
};
</script>

<style scoped>
</style>
